$webkit-filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));



.layout-editor-title{
  color: #fff;
  text-align: center;
}
.layout-creator-grid-wrapper{
  height: 100vh;
  width: 100%;
  position: relative;
}
.layout-creator-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
}
.layout-creator-row {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}
.layout-creator-cell {
  flex-grow: 1;
  border: 1px dashed rgba(0, 200, 255,1);
  border-left: 1px dashed rgba(0, 200, 255,1);
  border-top: 1px dashed rgba(0, 200, 255,1);
}
.layout-creator-cell-top-left {
  border-radius: 0.25rem 0 0 0;
}
.layout-creator-cell-bottom-left {
  border-radius: 0 0 0 0.25rem;
}
.layout-creator-cell-top-right {
  border-radius: 0 0.25rem 0 0;
}
.layout-creator-cell-bottom-right {
  border-radius: 0 0 0.25rem 0;
}
.layout-creator-cell-left {
  border-left: none;
}
.layout-creator-cell-top {
  border-top: none;
}
.layout-editor-widget-inner-wrapper{
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.widget-card-row {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}
.widget-card-cell {
  flex-grow: 1;
}
.layout-editor-widget-container{
  display: flex;
  flex-direction: column;
}
.layout-editor-widget-container-selected{
  transition: 0.5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  & span:nth-child(1){
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, transparent,white);
    animation: border-loop 2s linear infinite;
    z-index: 999;
  }
   @keyframes border-loop{
    0%{
      transform: translateX(-100%);
    }
    100%{
      transform: translateX(100%);
    }
    
  }
  & span:nth-child(2){
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, white);
    animation: border-loop2 2s linear infinite;
    animation-delay: 1s;
    z-index: 999;
    
  }
   @keyframes border-loop2{
    0%{
      transform: translateY(-100%);
    }
    100%{
      transform: translateY(100%);
    }
  }
  & span:nth-child(3){
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to left, transparent, white);
    animation: border-loop3 2s linear infinite;
z-index: 999;
    
  }
   @keyframes border-loop3{
    0%{
      transform: translateX(100%);
    }
    100%{
      transform: translateX(-100%);
    }
  }
  & span:nth-child(4){
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to top, transparent,white);
    animation: border-loop4 2s linear infinite;
    animation-delay: 1s;
z-index: 999;
    
  }
   @keyframes border-loop4{
    0%{
      transform: translateY(100%);
    }
    100%{
      transform: translateY(-100%);
    }
  }

}
.glass-effect{
  animation: mirror 4s linear infinite;
  transition: 1s;
  left: -100%;
  position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,transparent,rgba(white, 0.15),transparent);
    z-index: 999;
}
@keyframes mirror {
  0%{
    left: -250%;
  }
  50%{
    left: 0%;
  }
  100%{
    left: 250%;
  }
}

.layout-editor-widget-preview{
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.layout-editor-save{
  color: #fff !important;
  border-color: #fff !important;
  transition: all 0.1s ease-in-out !important;
}
.layout-editor-save:hover{
  background-color: transparent !important;
  transform: scale(1.05);
}
.layout-editor-save:focus{
  background-color: transparent !important;
  box-shadow: none !important;
}
.layout-editor-save:active{
  background-color: transparent !important;
  box-shadow: none !important;
}
.Toastify__toast--success{
  border: 1px solid white !important;
  background-color: transparent !important;
  color: white !important;
 
  & .Toastify__close-button{
    color: white;
    opacity: 1;
  }
  & .Toastify__toast-icon svg{
    fill: white;
  }
  & .Toastify__progress-bar{
    background-color: white;
  }
}