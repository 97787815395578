.sidebar-editor-item{
    width: 90%;
    height: 9vw;
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center;
}

.sidebar-item-list{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}