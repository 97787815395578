@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300&display=swap');

body,html {
    margin: 0;
    padding: 0;
    background-color: #000 !important;
    font-family: 'Jura', sans-serif !important;
}
* {
    box-sizing: border-box;
}
.App{
    height: 100vh;
    width: 100vw;
}
.cursor-pointer{
    cursor: pointer;
}
body ::-webkit-scrollbar {
    display: none;
}

.my-custom-button{
    background-color: transparent !important;
    color: #fff !important;
    border-color: #fff !important;
    transition: all 0.1s ease-in-out !important;
}
.my-custom-button:hover{
    background-color: transparent !important;
    transform: scale(1.03);
}
.my-custom-button:focus{
    background-color: transparent !important;
    box-shadow: none !important;
}
.my-custom-button:active{
    background-color: transparent !important;
    box-shadow: none !important;
}