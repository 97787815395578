
.confirmation-button-modal{
  /* background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important; */
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(5px) !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.confirmation-button-backdrop{
  opacity: 0.8 !important;
  backdrop-filter: blur(5px) !important;
}
.confirmation-button-header{
  font-size: 1.5em;
}