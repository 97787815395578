.fitting-room-image{
    height: 100vh;
    width: auto;
}
.webcam-container video{
    width: 100%;
    visibility: hidden;
}
.clothing-videos-wrapper-outer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.clothing-videos-wrapper-inner{
    display: flex;
    flex-direction: row;
    width: 70%;
    border: 1px solid #fff;
    border-radius: 10px;
    position: relative;
    overflow: scroll;
    height: 100px;
}
.clothing-video-thumb-container{
    margin: 0.15rem;
    border-radius: 10px;
    overflow: hidden;
    min-width: 100px;
    height: auto;
}
.clothing-video-thumb-container img{
    width: 100%;
    height: 100%;
}
.comparison-video-container{
    width: 100%;
}
.comparison-video-container video{
    width: 100%;
    display: block;
    visibility: visible;
}
.video-buttons{
    color: #fff;
    background-color: transparent !important;
    border: none !important;
}
.video-buttons:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.video-btn-text{
    margin-left: 5px;
}
.fitting-room-comparison-row{
    width: 70%;
    margin-left: 15% !important;
}