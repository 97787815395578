.mirror-background-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.mirror-background-container-file {
    height: 100%;
    width: 100%;
    object-fit: cover;
}