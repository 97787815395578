.board-row:after {
    clear: both;
    content: "";
    display: table;
}

.status {
    color: #424242;
    margin-bottom: 10px;
}

table {
    border-collapse: collapse;
  }
  table td {
    border: 5px solid white; 
  }
  table tr:first-child td {
    border-top: 0;
  }
  table tr td:first-child {
    border-left: 0;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table tr td:last-child {
    border-right: 0;
  }

.square {
    color: white;
    background: transparent;
    float: left;
    font-size: 120px;
    font-weight: bold;
    line-height: 200px;
    height: 200px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 200px;
}

.square:focus {
    outline: none;
}

.kbd-navigation .square:focus {
    background: #ddd;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 80%;
    width: 100%;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.container span {
    margin-top: -20%;
    margin-bottom: 5%;
    font-size: 100px;
    animation: fadein 0.5s linear;
}

.game {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.game-info {
    font-size: 50px;
    color: white;
    margin-bottom: 15%;
    text-align: center;
    min-width: 100%;
}
.game-container{
  animation: fadein 0.5s linear;
}
.game-fill{
  animation: fadein 0.5s linear;
}
@keyframes fadein {
  0% {opacity: 0;}
  100% { opacity: 1; }
}

.game-container button{
  width:250px;
  height: 80px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  margin: 0 40px;
  font-size: 25px;
  font-weight: bold;
}

.restart-button {
  display: flex;
    margin-top: 15%;
    text-align: center;
}

.restart {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    font-size: 24px;
    height: 50px;
    width: 150px;
    text-align: center;
    border-radius: 4px;
    margin: 10px 40px;
}