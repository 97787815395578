.profiles-container{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-item-container{
  border-radius: 10px;
  height: 15vw;
  width: 15vw;
  flex-direction: column;
  position: relative;
  transition: transform 1.3s;
}
.profile-item-container:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0px 0px 90px 30px rgba(255,255,255);
    -webkit-box-shadow: 0px 0px 90px 30px rgb(255, 255, 255);
    -moz-box-shadow: 0px 0px 90px 30px rgba(255, 255, 255);
}
.profile-item-container:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 0;
  opacity: 1;
}
.profile-item-container:active {
  transform: translateY(40px);
}
.profile-item-container span{
  position: absolute;
  bottom: 5%;
}
.Toastify__toast--error{
border: 1px solid white !important;
background-color: transparent !important;
color: white !important;
}
.Toastify__toast--error .Toastify__close-button{
  color: white;
  opacity: 1;
}
.Toastify__toast--error .Toastify__toast-icon svg{
  fill: white;
}
.Toastify__toast--error .Toastify__progress-bar{
  background-color: white;
}