.sidebar-wrapper{
    position: fixed;
    top: 50%;
    width: 10vw;
    height: 50vh;
    border-radius: 10px;
    z-index: 100;
    display: flex;
    align-items: center;
    display: block;
    overflow-y: scroll;
    transform: translate(0%, -50%);
    transition: 0.5s all;
}

.sidebar-wrapper-button {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    width: 3vw;
    border-radius: 10px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    transition: 0.5s all;
}

.sidebar-wrapper-button svg {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.9));
}

.sidebar-item-list{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    overflow-y: scroll;
}

.sidebar-item{
    margin: 0 auto;
    width: 90%;
    height: 9vw;
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
