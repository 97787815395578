.main-container {
  height: 100%;
  width: 100%;
  overflow:hidden;
  position: relative;
  color: white;
  background-color: transparent;
  object-fit: cover;
  z-index: 99;
    & .glass-effect-layout{
      animation: shine 5s ease-in-out;
      animation-fill-mode: forwards;  
      position: absolute;
      top: -110%;
      left: -310%;
      width: 280%;
      height: 200%;
      opacity: 0;
      transform: rotate(30deg);
          background: linear-gradient(90deg,transparent,rgba(white, 0.45),transparent);
      }
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: 40%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease-in-out;
  }
  30% {
    opacity: 0;
    top: -50%;
    left: 250%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease-in-out;
  }
  50% {
    opacity: 1;
    top: -80%;
    left: -280%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease-in-out;
  }
  75% {
    opacity: 1;
    top: -80%;
    left: -350%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease-in-out;
  }
  100% {
    opacity: 1;
    top: -80%;
    left: -420%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease-in-out;
  }
}
}

.widget-container {
  position: absolute;
  overflow: visible;
}
